import React, { useEffect } from "react";
import { graphql, useStaticQuery, Link } from 'gatsby';
import { ResponsiveContext } from 'grommet';
import {
  Footer,
  FormModal,
  FormModalContext,
  Header,
  HeaderAlt,
  Layout,
  SEO,
  StoryContext,
  insightsofficerTheme,
  linkResolver,
  getParams,
  useShowModal,
} from '@pwc-new-ventures/ui';

const postPrefix = 'resources';

const { GATSBY_MAIL_SEND_ENDPOINT } = process.env;

export default function({ children, description, title, image, formModalSlug }) {
  const [showModal, setShowModal] = useShowModal();

  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
        }
      }
      contentfulFooter (slug: {eq: "bc"}) {
        ctaText
        ctaLink {
          slug
          internal {
            type
          }
        }
        column1Header
        column2Header
        column3Header
        column3Links {
          ...FooterLink
        }
        pwcLink
        pwcLogo {
          ...ImageSharpOriginal
        }
        socialMediaLinks {
          icon
          name
          url
        }
      }
      contentfulMainMenu (slug: {eq: "bc"}) {
        ctaLink {
          slug
          internal {
            type
          }
        }
        ctaText
        loginLinkText
        loginLinkUrl
        mainLogo {
          ...ImageSharpOriginal
        }
      }
      allContentfulForm(filter: { slug: { regex: "/^(?!for-placement-only)/" } }) {
        nodes {
          elqFormName
          elqFormUrl
          elqSiteID
          headline
          subheadline {
            subheadline
          }
          formFields {
            fieldLabel
            fieldName
            fieldType
            fullWidth
            hiddenValue
            selectOptions
            required
          }
          internal {
            type
          }
          slug
          successBlurb {
            successBlurb
          }
          submitText
          submitType
          successCloseText
          successHeadline
        }
      }
    }
  `);

  const { allContentfulForm, contentfulFooter, contentfulMainMenu } = data;

  const menuLinks = [
    {
      name: 'How it works',
      url: '/how-it-works',
    },
    {
      name: "What's included",
      url: '/whats-included',
    },
    {
      name: 'Resources',
      url: '/resources',
    },
    {
      name: 'pwc.com',
      url: 'https://www.pwc.com/us/en/products.html',
    }
  ];

  const header = {
    logoUrl: contentfulMainMenu.mainLogo.localFile.publicURL,
    loginLink: {
      name: contentfulMainMenu.loginLinkText,
      url: contentfulMainMenu.loginLinkUrl,
    },
    primaryLink: {
      name: contentfulMainMenu.ctaText,
      url:
        contentfulMainMenu.ctaLink &&
        linkResolver({
          type: contentfulMainMenu.ctaLink.internal.type,
          slug: contentfulMainMenu.ctaLink.slug,
          postPrefix,
        }),
    },
    menuLinks,
  };

  const footer = {
    logoUrl: contentfulFooter.pwcLogo.localFile.publicURL,
    column1Header: contentfulFooter.column1Header,
    column2Header: contentfulFooter.column2Header,
    column3Header: contentfulFooter.column3Header,
    column3Links: contentfulFooter.column3Links.map((link) => ({
      name: link.menuName,
      url: linkResolver({
        type: link.internal.type,
        slug: link.redirectUrl || link.slug,
        postPrefix,
      }),
    })),
    pwcLink: contentfulFooter.pwcLink,
    socialLinks: contentfulFooter.socialMediaLinks.map(({ icon, name, url }) => ({
      icon,
      name,
      url,
    })),
    primaryLink: {
      name: contentfulFooter.ctaText,
      url:
        contentfulFooter.ctaLink &&
        linkResolver({
          type: contentfulFooter.ctaLink.internal.type,
          slug: contentfulFooter.ctaLink.slug,
          postPrefix,
        }),
    },
    menuLinks,
  };

  const forms = allContentfulForm.nodes.map((form) => ({
    mailSendEndpoint: GATSBY_MAIL_SEND_ENDPOINT,
    headline: form.headline,
    subheadline: form.subheadline.subheadline,
    buttonText: form.submitText,
    fields: form.formFields,
    submitType: form.submitType,
    hiddenFields: {
      elqFormName: form.elqFormName,
      elqFormUrl: form.elqFormUrl,
      elqSiteID: form.elqSiteID,
    },
    slug: form.slug,
    success: {
      headline: form.successHeadline,
      body: form.successBlurb.successBlurb,
      buttonText: form.successCloseText,
    },
    borderColor: 'light-4',
  }));

  useEffect(() => {
    getParams();
    setShowModal(formModalSlug)
  }, [])

  return (
    <>
      <SEO data={data} description={description} title={title} image={image} />
      <Layout theme={insightsofficerTheme}>
      <ResponsiveContext.Consumer>
        {(size) => {
          const mobile = ['small', 'hero'].includes(size);
          // console.log('responsive', size, mobile)

        return (
          <StoryContext.Provider value={{ postPrefix }}>
            <FormModalContext.Provider value={{ showModal, setShowModal }}>
              { mobile ? 
              <Header Link={Link} background="white" mobileBackground="dark-1" {...header} />
              :
              <HeaderAlt Link={Link} background="white" mobileBackground="dark-1" {...header} />
              }
              <main>{children}</main>
              <Footer Link={Link} {...footer} />
              {forms && forms.map((form) => <FormModal {...form} />)}
            </FormModalContext.Provider>
          </StoryContext.Provider>
        )}}
        </ResponsiveContext.Consumer>
      </Layout>
    </>
  )
}